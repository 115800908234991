<template>
  <div class="SensitiveMonitor">
    <el-row :gutter="4" class="mt15" style="display: flex">
      <el-col :span="3">
        <!-- 起始日期 -->
        <el-date-picker
          :clearable="false"
          size="small"
          style="width: 100%"
          v-model="formInline.startTime"
          type="date"
          :placeholder="$t('SecurityMonitor.Violation.SelectDate')"
          value-format="yyyy-MM-dd"
          :picker-options="startTimePickerOptions"
        >
        <!-- :picker-options="pickerOptions" -->
        </el-date-picker>
      </el-col>
      <el-col :span="0.5">
        <span style="line-height: 30px; color: #dcdfe6">~</span>
      </el-col>
      <el-col :span="3">
        <el-date-picker
          :clearable="false"
          size="small"
          style="width: 100%"
          v-model="formInline.endTime"
          type="date"
          :placeholder="$t('SecurityMonitor.Violation.SelectDate')"
          value-format="yyyy-MM-dd"
          :picker-options="endTimePickerOptions"
        >
        <!-- :picker-options="pickerOptions" -->
        </el-date-picker>
      </el-col>
      <el-col :span="3">
        <!-- 访问结果 -->
        <el-select
          v-model="formInline.result"
          style="width: 100%"
          :placeholder="$t('SecurityMonitor.Accessbe.AccessResults')"
          size="small"
          class="placeInput"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="$t(`SecurityMonitor.Accessbe.AccessResu.${item.label}`)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <select-input
        :seleShift="seleShift"
        :inputText="formInline.input"
        :options="devNameOptions"
        @selectSearchChange="selectSearchChange"
        @searchList="onSubmit"
      ></select-input>
      <el-col :span="3">
        <el-button size="small" type="primary" @click="advancedBtn">{{
          $t("public.Derive")
        }}</el-button>
      </el-col>
    </el-row>
    <!-- 点击选中只显示对应内容 -->
    <el-row :gutter="4" style="position: relative">
      <div
        style="
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          right: 5px;
          top: -32px;
          cursor: pointer;
        "
      >
        <!-- 弹出框 -->
        <el-popover trigger="click" popper-class="LinkageTable">
          <el-checkbox-group @change="tableTitleTime" v-model="checkList">
            <el-checkbox
              v-for="(item, index) in tradeSelections"
              :key="index"
              :label="$t(`SecurityMonitor.Accessbe.Tablelinkage.${item.eng}`)"
              :checked="item.isChecked"
              :disabled="item.flag"
              @click.native="sss(item.eng, index, item.flag)"
              >{{
                $t(`SecurityMonitor.Accessbe.Tablelinkage.${item.name}`)
              }}</el-checkbox
            >
          </el-checkbox-group>
          <img
            slot="reference"
            src="@/images/showConfig.png"
            alt=""
            style="width: 20px; height: 20px"
          />
        </el-popover>
      </div>
      <!-- 表格 -->
      <el-table
        size="mini"
        ref="myTable"
        :data="tableData"
        stripe
        fit
        tooltip-effect="dark"
        class="tableClass"
        @sort-change="sort"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
        :default-sort="{ prop: 'create_time', order: 'ascending' }"
      >
        <template>
          <el-table-column
            v-if="$filter.nameFilter(formThead, tradeSelections)"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              width="120"
              v-for="(item, index) in formThead"
              :key="index"
              :label="item.name"
            >
              <template slot-scope="scope">
                {{ scope.row[item.eng] }}
              </template>
            </el-table-column>
          </el-table-column>
        </template>
        <el-table-column
          :show-overflow-tooltip="true"
          fixed
          width="120"
          :label="$t('public.Username')"
          prop="DEVIVENAME"
          v-if="tradeSelections[tradeSelections.length - 17].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.loginName }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.Name')"
          prop="USERNAME"
          v-if="tradeSelections[tradeSelections.length - 16].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.userName }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.Section')"
          prop="GROUPFULLNAME"
          v-if="tradeSelections[tradeSelections.length - 15].isChecked"
        >
          <template v-slot="{ row }">
            <!-- row.groupfullname.substr(groupFullNameShowByIndex) -->
            <span v-if="row.groupFullName">{{
              row.groupFullName.substr(groupFullNameShowByIndex)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          fixed
          width="120"
          :label="$t('public.DeviceName')"
          prop="DEVIVENAME"
          v-if="tradeSelections[tradeSelections.length - 14].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.deviceName }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          fixed
          width="120"
          :label="$t('SecurityMonitor.Accessbe.Sourcedevice')"
          prop="TYPE"
          v-if="tradeSelections[tradeSelections.length - 13].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.sourceAddr }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          fixed
          width="120"
          :label="$t('SecurityMonitor.Accessbe.SourceID')"
          prop="TYPE"
          v-if="tradeSelections[tradeSelections.length - 12].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.udid }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.ApplicationName')"
          prop="NAME"
          v-if="tradeSelections[tradeSelections.length - 11].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.appName }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('SecurityMonitor.Accessbe.AppPackageName')"
          prop="VERSION"
          v-if="tradeSelections[tradeSelections.length - 10].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.packageName }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('SecurityMonitor.Accessbe.GatewayName')"
          prop="OS_VERSION"
          v-if="tradeSelections[tradeSelections.length - 9].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.gatewayName }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('SecurityMonitor.Accessbe.GatewayAddress')"
          v-if="tradeSelections[tradeSelections.length - 8].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.gatewayAddr }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('SecurityMonitor.Accessbe.Gatewayport')"
          v-if="tradeSelections[tradeSelections.length - 7].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.gatewayPort }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('SecurityMonitor.Accessbe.ResourceName')"
          prop="NET_TYPE"
          v-if="tradeSelections[tradeSelections.length - 6].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.targetName }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('SecurityMonitor.Accessbe.ResourceAddress')"
          prop="OUT_IP"
          v-if="tradeSelections[tradeSelections.length - 5].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.targetAddr }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('SecurityMonitor.Accessbe.ResourcePort')"
          prop="IP"
          v-if="tradeSelections[tradeSelections.length - 4].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.targetPort }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('SecurityMonitor.Accessbe.AccessResults')"
          prop="WIFI_SSID"
          v-if="tradeSelections[tradeSelections.length - 3].isChecked"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.result == '1'">{{
              $t("SecurityMonitor.Accessbe.Success")
            }}</template>
            <template v-if="scope.row.result == '0'">{{
              $t("SecurityMonitor.Accessbe.Fail")
            }}</template>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="140"
          :label="$t('SecurityMonitor.Accessbe.Accessedtime')"
          prop="TIME"
          v-if="tradeSelections[tradeSelections.length - 2].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.uploadTime }}
          </template>
        </el-table-column>

        <el-table-column fixed="right" :label="$t('public.Controls')">
          <template slot-scope="scope">
            <span
              size="small"
              @click="details(scope.row)"
              style="color: #d38a08; cursor: pointer"
              >{{ $t("public.Details") }}</span
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        :page="currentPage"
        :limit="pageSize"
        :total="total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      ></pagination>
    </el-row>
    <!-- 详情弹框 -->
    <Eldialog
      @handleClose="handleClose"
      :title="$t('public.Details')"
      :dialogVisible="outerVisible"
      :flagbtn="false"
      :num="'470px'"
    >
      <Accessbehavior :detailData="detailData" />
    </Eldialog>
  </div>
</template>
<script>
import selectInput from "@/components/selectInput";
import { regionData } from "element-china-area-data"; //引入
import options from "@/utils/country-level3-data"; //三级城市数据
import Eldialog from "@/components/elDialog/index.vue";
import pagination from "../../components/pagination/page";
import Accessbehavior from "./components/Accessbehavior.vue";
export default {
  components: {
    Eldialog,
    selectInput,
    pagination,
    Accessbehavior,
  },

  data() {
    return {
      // 联动

      key: 1, //table  key
      flag: 1,
      props: { multiple: true },
      total: 0,
      number: 0,
      number1: 0,
      pageSize: 10, //每页显示条数
      cityarrs: [],
      checkList: [], //选中的选项eng数组
      formThead: [], //渲染表头
      seleShift: "", // 选中下拉框转译
      tableData: [], //列表data
      orderRule: "DESC", //排序规则ASC、DESC
      detailData: [], //详情data

      formInline: {
        input: "",
        result: "",
        deviceName: "用户名",
        // 选择日期
        startTime: "",
        endTime: "",
      },
      startTimePickerOptions: {  
        // 这里可以放置 startTime 的禁用逻辑，如果有的话  
        // 但由于你不需要禁用 startTime 的任何日期，所以可能不需要  
        disabledDate: (time) => {  
          // 如果没有设置 startTime，则禁用所有日期  
          if (!this.formInline.endTime) {  
            return true;  
          }  
          // 将 startTime 转换为 Date 对象（如果它还不是的话）  
          const endTime = this.$moment(this.formInline.startendTimeTime, "YYYY-MM-DD").toDate();  
          // 禁用早于 startTime 和晚于当前日期的日期  
          return time.getTime() < endTime.getTime() || time.getTime() > new Date().getTime();  
        },  
      },  
      endTimePickerOptions: {  
        disabledDate: (time) => {  
          // 如果没有设置 startTime，则禁用所有日期  
          if (!this.formInline.startTime) {  
            return true;  
          }  
          // 将 startTime 转换为 Date 对象（如果它还不是的话）  
          const startTime = this.$moment(this.formInline.startTime, "YYYY-MM-DD").toDate();  
          // 禁用早于 startTime 和晚于当前日期的日期  
          return time.getTime() < startTime.getTime() || time.getTime() > new Date().getTime();  
        },  
      },
      currentPage: 1, //当前页
      orderColume: "create_time", //排序字段
      typeOptions: [
        { value: "", label: "访问结果" },
        { value: "1", label: "成功" },
        { value: "0", label: "失败" },
      ],
      outerVisible: false,

      devNameOptions: [
        { value: "loginName", label: "用户名" },
        { value: "deviceName", label: "设备名称" },
        { value: "appName", label: "应用名称" },
        { value: "gatewayName", label: "网关名称" },
        { value: "targetName", label: "资源名称" },
      ], //设备名称
      tbodyData: [], // 配置项数据
      formCustom: {
        Detail: "", //详细地址信息
        DetailMap: "", //城市地址详细地址拼接
        address: "", //省份城市
      },
      placeholder: "用户名",
      cityOptions: options, // 城市数据
      selectedOptions: [], //存放默认值
      tradeSelections: [
        { eng: "loginName", name: "用户名", isChecked: true, flag: true },
        { eng: "userName", name: "姓名", isChecked: false, flag: false },
        { eng: "groupFullName", name: "部门", isChecked: false, flag: false },
        { eng: "deviceName", name: "设备名称", isChecked: true, flag: true },
        { eng: "sourceAddr", name: "源设备地址", isChecked: true, flag: true },
        { eng: "udid", name: "源设备ID", isChecked: false, flag: false },
        { eng: "appName", name: "应用名称", isChecked: true, flag: true },
        { eng: "packageName", name: "应用包名", isChecked: false, flag: false },
        { eng: "gatewayName", name: "网关名称", isChecked: true, flag: true },
        { eng: "gatewayAddr", name: "网关地址", isChecked: true, flag: true },
        { eng: "gatewayPort", name: "网关端口", isChecked: false, flag: false },
        { eng: "targetName", name: "资源名称", isChecked: true, flag: true },
        { eng: "targetAddr", name: "资源地址", isChecked: true, flag: true },
        { eng: "targetPort", name: "资源端口", isChecked: false, flag: false },
        { eng: "result", name: "访问结果", isChecked: true, flag: true },
        { eng: "uploadTime", name: "访问时间", isChecked: true, flag: true },
        { eng: "decide", name: "操作", isChecked: true, flag: true },
      ], // isChecked：true选中(默认)false不选中    flag：true是禁用(不可编辑)false不禁用(可编辑)
      tradeSelections1: [],
      groupFullNameShowByIndex: "", //用户部门截取的下标
      // 日历时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      selectVal: {
        deviceName: "",
        InputText: "",
      },
    };
  },

  watch: {
    checkList(val) {
      this.formThead = this.tradeSelections.filter(
        (i) => val.indexOf(i.eng) >= 0
      );
      this.key - this.key + 1;
    },
  },

  created() {},
  //取token
  beforeMount() {
    const token = sessionStorage.getItem("userName");
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    if (this.$route.query.name) {
      this.MingTime();
    }
    // 判断日期
    if (this.$route.query.fl == true) {
      this.Day();
    } else if (this.$route.query.fl == false) {
      this.Mounth();
    } else if (this.$route.query.card == true) {
      this.card();
    } else if (this.$route.query.cli == true) {
      this.formInline.deviceName = this.$t("public.Username");
      this.seleShift = "loginName";
      console.log(this.$route.query.name, "this.$route.query.name");
      console.log(this.formInline.input, "this.formInline.input");
      this.formInline.input = this.$route.query.name;
      this.formInline.startTime = this.$route.query.starttime.split(" ")[0];
      this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
      this.getDataList(this.selectVal);
    } else if (this.$route.query.deviceAccess == true) {
      this.formInline.deviceName = this.$t("public.DeviceName");
      this.seleShift = "deviceName";
      this.formInline.input = this.$route.query.name;
      this.formInline.startTime = this.$route.query.starttime.split(" ")[0];
      this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
      this.getDataList(this.selectVal);
    } else if (this.$route.query.appAccess == true) {
      this.formInline.deviceName = this.$t("public.ApplicationName");
      this.seleShift = "appName";
      this.formInline.input = this.$route.query.name;
      this.formInline.startTime = this.$route.query.starttime.split(" ")[0];
      this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
      this.getDataList(this.selectVal);
    } else if (this.$route.query.sourceAccess == true) {
      this.formInline.deviceName = this.$t(
        "SecurityMonitor.Accessbe.ResourceName"
      );
      this.seleShift = "targetName";
      this.formInline.input = this.$route.query.name;
      this.formInline.startTime = this.$route.query.starttime.split(" ")[0];
      this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
      this.getDataList(this.selectVal);
    } else if (this.$route.query.businessAccessTrend == true) {
      this.formInline.deviceName = this.$t(
        "SecurityMonitor.Accessbe.ResourceName"
      );
      this.seleShift = "targetName";

      if (
        this.$route.query.accessResult ==
        this.$t("SecurityMonitor.Accessbe.BusinessActive")
      ) {
        this.formInline.result = "1";
      } else {
        this.formInline.result = "0";
      }
      this.formInline.input =
        this.$route.query.name ==
        this.$t("SecurityMonitor.Accessbe.Allbusiness")
          ? ""
          : this.$route.query.name;
      this.businessJump();
      this.getDataList(this.selectVal);
    } else if (this.$route.query.gatewayAddCart == true) {
      this.formInline.result = "1";
      this.formInline.startTime = this.$route.query.starttime.split(" ")[0];
      this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
      this.getDataList(this.selectVal);
    } else {
      this.formInline.startTime = this.$moment()
        .subtract("days", 7)
        .format("YYYY-MM-DD");
      console.log(this.formInline.startTime);
      this.formInline.endTime = this.$moment().format("YYYY-MM-DD");
      console.log(this.formInline.endTime);
      this.getDataList(this.selectVal);
      // 原代码日期
      // this.formInline.startTime = this.$common.getAnyMonth('-', -1)
      // this.formInline.endTime = this.$common.getDate()
    }
    if (this.$route.query.cli == true) {
      this.formInline.startTime = this.$route.query.starttime.split(" ")[0];
      this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
      this.getDataList(this.selectVal);
    }
  },
  updated() {
    // myTable是表格的ref属性值
    if (this.$refs.myTable && this.$refs.myTable.doLayout) {
      this.$refs.myTable.doLayout();
    }
  },
  methods: {
    selectSearchChange(val) {
      console.log(val, "val----yayaya");
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    // 排序@sort-change="sort"
    sort(column) {
      if (column.order === "ascending") {
        this.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.orderRule = "DESC";
      }
      this.orderColume = column.prop;
      this.onSubmit(); //再次调用查询接口
    },
    // 详情关闭按钮
    handleClose() {
      this.outerVisible = false;
    },
    Day() {
      // 安全态势的跳转
      this.formInline.startTime = this.$route.query.dataDay.split(" ")[0];
      console.log(this.formInline.startTime, "当日");
      this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
      console.log(this.formInline.endTime, "当日");
      this.onSubmit();
    },
    Mounth() {
      // 安全态势的跳转
      this.formInline.startTime = this.$route.query.dataMounth.split(" ")[0];
      this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
      this.onSubmit();
    },
    card() {
      this.formInline.startTime = this.$route.query.starttime.split(" ")[0];
      this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
    },

    getdatatime() {
      //默认显示今天

      return new Date().getTime();
    },
    UTCformat(utc) {
      var date = new Date(utc),
        year = date.getFullYear(),
        month =
          date.getMonth() + 1 > 9
            ? date.getMonth() + 1
            : "0" + parseInt(date.getMonth() + 1),
        day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate(),
        hour = date.getHours() > 9 ? date.getHours() : "0" + date.getHours(),
        minutes =
          date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes(),
        seconds =
          date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
      var res = year + "-" + month + "-" + day + " ";
      return res;
    },
    MingTime() {
      // 安全态势的跳转
      if (this.$route.query.starttime) {
        if (
          this.$route.query.starttime == "00" ||
          this.$route.query.starttime == "01" ||
          this.$route.query.starttime == "02" ||
          this.$route.query.starttime == "03" ||
          this.$route.query.starttime == "04" ||
          this.$route.query.starttime == "05" ||
          this.$route.query.starttime == "06" ||
          this.$route.query.starttime == "07" ||
          this.$route.query.starttime == "08" ||
          this.$route.query.starttime == "09" ||
          this.$route.query.starttime == "10" ||
          this.$route.query.starttime == "11" ||
          this.$route.query.starttime == "12" ||
          this.$route.query.starttime == "13" ||
          this.$route.query.starttime == "14" ||
          this.$route.query.starttime == "15" ||
          this.$route.query.starttime == "16" ||
          this.$route.query.starttime == "17" ||
          this.$route.query.starttime == "18" ||
          this.$route.query.starttime == "19" ||
          this.$route.query.starttime == "20" ||
          this.$route.query.starttime == "21" ||
          this.$route.query.starttime == "22" ||
          this.$route.query.starttime == "23" ||
          this.$route.query.starttime == "24"
        ) {
          console.log(this.$common.getDate(), "this.$common.getDate()");
          this.formInline.startTime = this.$common.getDate();
          this.formInline.endTime = this.$common.getDate();
          this.getDataList();
        } else if (this.$route.query.starttime.includes("-")) {
          this.formInline.startTime = this.$route.query.starttime.split(" ")[0];
          this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
          this.getDataList();
        }
      }
    },
    businessJump() {
      // 安全态势的跳转
      if (
        this.$route.query.starttime == "00" ||
        this.$route.query.starttime == "01" ||
        this.$route.query.starttime == "02" ||
        this.$route.query.starttime == "03" ||
        this.$route.query.starttime == "04" ||
        this.$route.query.starttime == "05" ||
        this.$route.query.starttime == "06" ||
        this.$route.query.starttime == "07" ||
        this.$route.query.starttime == "08" ||
        this.$route.query.starttime == "09" ||
        this.$route.query.starttime == "10" ||
        this.$route.query.starttime == "11" ||
        this.$route.query.starttime == "12" ||
        this.$route.query.starttime == "13" ||
        this.$route.query.starttime == "14" ||
        this.$route.query.starttime == "15" ||
        this.$route.query.starttime == "16" ||
        this.$route.query.starttime == "17" ||
        this.$route.query.starttime == "18" ||
        this.$route.query.starttime == "19" ||
        this.$route.query.starttime == "20" ||
        this.$route.query.starttime == "21" ||
        this.$route.query.starttime == "22" ||
        this.$route.query.starttime == "23" ||
        this.$route.query.starttime == "24"
      ) {
        this.formInline.startTime = this.UTCformat(this.getdatatime()).split(
          " "
        )[0];
        this.formInline.endTime = this.UTCformat(this.getdatatime()).split(
          " "
        )[0];
        return;
      } else if (this.$route.query.starttime) {
        this.formInline.startTime = this.$route.query.starttime.split(" ")[0];
        this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
      }
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page;
      console.log(this.currentPage, "一页也");
      this.pageSize = limit;
      console.log(this.pageSize, "总过条数");
      // 调用查询方法
      // this.getDataList(this.selectVal)
      this.getDataList();
    },
    tableTitleTime(i, k) {},
    sss(index, key, event) {
      if (event == true) {
        return false;
      }
      if (this.number == 1) {
        if (
          index == "loginName" ||
          index == "userName" ||
          index == "groupFullName" ||
          index == "deviceName" ||
          index == "sourceAddr" ||
          index == "udid" ||
          index == "appName " ||
          index == "packageName" ||
          index == "gatewayName" ||
          index == "gatewayAddr" ||
          index == "gatewayPort" ||
          index == "targetName" ||
          index == "targetAddr" ||
          index == "targetPort" ||
          index == "result" ||
          index == "uploadTime"
        ) {
          this.tradeSelections[key].isChecked =
            !this.tradeSelections[key].isChecked;
        }
        this.number = 0;
      } else {
        this.number = 1;
      }
    },

    // 列表
    async getDataList(val) {
      // if (this.formInline.startTime == null) {
      //   // this.titleInfo = '请选择开始时间'
      //   // this.messageFlag = true
      //   this.$message({
      //     message: this.$t("SecurityMonitor.Accessbe.SelectAStartTime"),
      //     type: "error",
      //     offset: 100,
      //   });
      // } else if (this.formInline.endTime == null) {
      //   this.$message({
      //     message: this.$t("SecurityMonitor.Accessbe.SelectAEndTime"),
      //     type: "error",
      //     offset: 100,
      //   });
      // } else if (this.formInline.startTime > this.formInline.endTime) {
      //   this.$message({
      //     message: this.$t("SecurityMonitor.Accessbe.EndnoStart"),
      //     type: "error",
      //     offset: 100,
      //   });
      // }
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        orderColume: this.orderColume,
        searchKey: val ? val.deviceName : this.seleShift,
        searchValue: val ? val.InputText : this.formInline.input,
        result: this.formInline.result,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        orderRule: this.orderRule,
      };
      console.log(parma, "参数列表----");
      const res = await this.$axios.post(
        "/httpServe/emmLogs/gatewayDataInfo",
        parma,
        true
      );
      console.log(res.data, "参数列表返回");
      this.tableData = res.data.content;
      this.total = res.data.total;
      console.log(this.total, "威胁行为返回的数据");
      for (var i = 0; i < this.tableData.length; i++) {
        // console.log("外层循环" + this.tableData[i].type + "次");
        // 对于为空的字段，显示N/A
        var obj2 = this.tableData[i];
        for (var key in obj2) {
          if (obj2[key] == null || obj2[key] == "") {
            obj2[key] = "N/A";
          }
        }
        for (var j = 0; j < this.typeOptions.length; j++) {
          if (this.tableData[i].type == this.typeOptions[j].type) {
            this.tableData[i].type = this.typeOptions[j].label;
          }
        }
      }
    },
    changeName(item) {
      console.log(item, "------item");
      this.placeholder = item;
      this.devNameOptions.forEach((k) => {
        console.log(k, "----------k------------");
        if (item == k.label) {
          this.seleShift = k.value;
          console.log(this.seleShift);
        }
      });
    },
    //查询
    async onSubmit(val) {
      console.log(val, "-------");
      this.selectVal = val;
      // debugger
      // if (this.formInline.input.length > 0) {
      // this.currentPage = 1
      // }
      // if (this.formInline.startTime > this.formInline.endTime) {
      //   this.$message({
      //     message: this.$t("SecurityMonitor.Accessbe.EndnoStart"),
      //     type: "error",
      //     offset: 100,
      //   });
      // }
      this.currentPage = 1
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        orderColume: this.orderColume,
        searchKey: val.deviceName,
        searchValue: val.InputText,
        result: this.formInline.result,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        orderRule: this.orderRule,
      };
      console.log(parma, "列表查询onsubmit");
      const res = await this.$axios.post(
        "/httpServe/emmLogs/gatewayDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
      // 对于为空的字段，显示N/A
      for (var i = 0; i < this.tableData.length; i++) {
        var obj2 = this.tableData[i];
        for (var key in obj2) {
          if (obj2[key] == null || obj2[key] == "") {
            obj2[key] = "N/A";
          }
        }
        for (var j = 0; j < this.typeOptions.length; j++) {
          if (this.tableData[i].type == this.typeOptions[j].type) {
            this.tableData[i].type = this.typeOptions[j].label;
          }
        }
      }
    },

    // 分页
    // handleSizeChange(val) {
    //   console.log('分页')
    //   this.pageSize = val
    //   this.getDataList()
    // },
    // handleCurrentChange(val) {
    //   console.log('handleCurrentChange')
    //   this.currentPage = val
    //   this.getDataList()
    // },
    //导出
    async advancedBtn() {
      let arrOut = [];
      this.tradeSelections.forEach((item) => {
        if (item.isChecked == true && item.name != this.$t("public.Controls")) {
          arrOut.push(item.eng); //去重
          this.tradeSelections1 = [...new Set(arrOut)];
        }
      });
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA";

      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );

      let groupId = JSON.parse(userString).userId;

      this.userId = groupId;

      if (this.$route.query.fl == true) {
        this.Day();
      } else if (this.$route.query.fl == false) {
        this.Mounth();
      }
      if (this.$route.query.cli == true) {
        this.MingTime();
      }
      if (this.$route.query.card == true) {
        this.card();
      }
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.total,
        orderColume: this.orderColume,
        searchKey: this.selectVal.deviceName
          ? this.selectVal.deviceName
          : this.seleShift,
        searchValue: this.selectVal.InputText
          ? this.selectVal.InputText
          : this.formInline.input,
        // searchKey: this.seleShift,
        // searchValue: this.formInline.input,
        result: this.formInline.result,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        orderRule: this.orderRule,
        exportField: this.tradeSelections1,
      };
      console.log(parma, "daochu");
      const res = await this.$axios.postExport(
        "/httpExport/emmLogs/exportGatewayExcel",
        parma,
        true,
        this.userId
      );
      const link = document.createElement("a");
      console.info(new Date());

      // let fileName = res.headers["content-disposition"].split("=")[1];
      var fileName = "exportAccessBehavior_" + Date.parse(new Date()) + ".xls";

      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 详情
    details(info) {
      console.log(info, "=======info=======");
      this.outerVisible = true;
      this.detailData = info;
      this.detailData["decideStr"] = eval(this.detailData.decide);
    },
    //配置项监听事件
    tableTitleTime(event) {},
  },
};
</script>

<style lang="scss" scoped>
.SensitiveMonitor {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
.location {
  &::v-deep {
    & ::placeholder {
      color: #606266;
    }
  }
}
@-moz-document url-prefix() {
  .location {
    &::v-deep {
      & ::placeholder {
        color: #606266;
        opacity: 1 !important; /*火狐默认有opacity:0.4; */
      }
    }
  }
}
.el-form-item__content {
  width: 100%;
  /* display: flex; */
  margin: 0 !important;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 130px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 140px;
}

::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.detailContent .boxBot .group .detailLabel[data-v-70181544] {
  width: 13%;
}
/* 设置高优先，以覆盖内联样式 Table组件固定列底部有一条白线
::v-deep  .el-table__fixed{
  height: 100vh !important;
}
::v-deep  .el-table__fixed-right{
  height: 100vh !important;
}*/
::v-deep .el-table__fixed::before {
  background-color: transparent !important; /*背景色完全透明*/
}
::v-deep .el-table__fixed-right::before {
  background-color: transparent !important; /*背景色完全透明*/
}
.placeInput {
  /* ---------------------------------------注意区分组件 */
  ::v-deep .el-cascader__tags .el-cascader__search-input::placeholder {
    color: #606266 !important;
  }
  /* 谷歌 */
  ::v-deep
    .el-cascader__tags
    .el-cascader__search-input::-webkit-input-placeholder {
    color: #606266 !important;
  }
  /* 火狐::-moz-placeholder */
  ::v-deep .el-cascader__tags .el-cascader__search-input::-moz-placeholder {
    color: #606266 !important;
    opacity: 1 !important; /*火狐默认有opacity:0.4; */
  }
  /*ie*/
  ::v-deep .el-cascader__tags .el-cascader__search-input:-ms-input-placeholder {
    color: #606266 !important;
  }
}
</style>
