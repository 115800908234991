<template>
  <div>
    <div class="detailContent">
      <!-- 访问行为 -->
      <p class="detailTitle">{{ $t('SecurityMonitor.Accessbe.AppInfor')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{ $t('public.ApplicationName')}}：</span>
            <span class="detaildesc">{{ detailData.appName }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Accessbe.AppPackageName')}}：</span>
            <span class="detaildesc">{{ detailData.packageName }}</span>
          </div>
        </div>
      </div>
      <!-- 基本信息 -->
      <p class="detailTitle">{{ $t('SecurityMonitor.Accessbe.BasicInformation')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{ $t('public.DeviceName')}}：</span>
            <span class="detaildesc">{{ detailData.deviceName }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="vertical-align: top">{{ $t('SecurityMonitor.Accessbe.UserDepartment')}}：</span>
            <!-- detailData.groupfullname.substr(groupFullNameShowByIndex) -->
            <span class="detaildesc" style="vertical-align: top">{{detailData.groupFullName.substr(groupFullNameShowByIndex)}}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Accessbe.DeviceIP')}}：</span>
            <span class="detaildesc">{{ detailData.sourceAddr }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Accessbe.EquipmentModel')}}：</span>
            <span class="detaildesc">{{ detailData.model }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t('public.Name')}}：</span>
            <span class="detaildesc">{{ detailData.userName }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="vertical-align: top">{{ $t('public.SystemVersion')}}：</span>
            <span class="detaildesc" style="vertical-align: top">{{ detailData.osVersion }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('public.Username')}}：</span>
            <span class="detaildesc">{{ detailData.loginName }}</span>
          </div>
          <div class="group">
            <!-- 2024.7.10wjw说产品要设备IMEI根据设备类型展示；android: 设备IMEI; iOS: UDID;Linux:网卡Mac; Windows:网卡Mac -->
            <span v-if="$common.getSystemName(detailData.osVersion)=='Android'" class="detailLabel" style="vertical-align: top"
              >{{ $t("public.IMEIAndroid") }}：</span
            >
            <span v-if="$common.getSystemName(detailData.osVersion)=='iOS'" class="detailLabel" style="vertical-align: top"
              >{{ $t("public.IMEIiOS") }}：</span
            >
            <span v-if="$common.getSystemName(detailData.osVersion)=='Linux'" class="detailLabel" style="vertical-align: top"
              >{{ $t("public.IMEIMac") }}：</span
            >
            <span v-if="$common.getSystemName(detailData.osVersion)=='Windows'" class="detailLabel" style="vertical-align: top"
              >{{ $t("public.IMEIMac") }}：</span
            >
            <span v-if="$common.getSystemName(detailData.osVersion)=='Unknown'" class="detailLabel" style="vertical-align: top"
              >{{ $t("public.IMEIUnknown") }}：</span
            >
            <span class="detaildesc" style="vertical-align: top">{{
              detailData.imei
            }}</span>
          </div>
        </div>
      </div>
      <!-- 访问信息 -->
      <p class="detailTitle">{{ $t('SecurityMonitor.Accessbe.Accessinfor')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Accessbe.GatewayName')}}：</span>
            <span class="detaildesc">{{ detailData.gatewayName }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Accessbe.Gatewayport')}}：</span>
            <span class="detaildesc">{{ detailData.gatewayPort }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Accessbe.ResourceAddress')}}：</span>
            <span class="detaildesc" style="vertical-align: top">{{ detailData.targetAddr }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Accessbe.AccessResults')}}：</span>
            <span class="detaildesc" v-if="detailData.result == '1'">{{ $t('SecurityMonitor.Accessbe.Success')}}</span>
            <span class="detaildesc" v-if="detailData.result == '0'">{{ $t('SecurityMonitor.Accessbe.Fail')}}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Accessbe.GatewayAddress')}}：</span>
            <span class="detaildesc">{{ detailData.gatewayAddr }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Accessbe.ResourceName')}}：</span>
            <span class="detaildesc">{{ detailData.targetName }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Accessbe.ResourcePort')}}：</span>
            <span class="detaildesc">{{ detailData.targetPort }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Accessbe.Accessedtime')}}：</span>
            <span class="detaildesc" v-if="detailData.uploadTime == ''"></span>
            <span class="detaildesc" v-else
              >{{ detailData.uploadTime }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",

  props: {
    detailData: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      groupFullNameShowByIndex: "",
    };
  },

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem("groupFullNameShowByIndex");
  },

  methods: {}
};
</script>

<style lang="scss" scoped>
.detailContent {
  width: 100%;

  // height: 469px !important;
  // overflow: auto !important;
  .detailTitle {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 700;
    height: 24px;
    line-height: 24px;
  }

  .detailbox {
    width: 100%;
    display: flex;
    font-size: 12px;

    .boxLeft {
      width: 50%;
      padding-left: 68px;
      line-height: 24px;

      .group {
        margin-top: 8px;

        .detailLabel {
          width: 44%;
          text-align: left;
        }

        .detaildesc {
          width: 67%;
          display: inline-block;
          overflow-y: visible;
        }
      }
    }

    .boxRight {
      width: 50%;
      line-height: 24px;

      .group {
        margin-top: 8px;

        .detailLabel {
          width: 23%;
          text-align: left;
        }

        .detaildesc {
          width: 67%;
          display: inline-block;
          overflow-y: visible;
        }
      }
    }
  }

  .boxBot {
    width: 100%;
    padding-left: 68px;
    font-size: 12px;

    .group {
      margin-top: 8px;

      .detailLabel {
        width: 22%;
        text-align: left;
        padding-right: 5px;
      }

      .detaildesc {
        width: 78%;
        display: inline-block;
      }
    }
  }
}

.detailContent .boxBot .group .detailLabel[data-v-70181544] {
  width: 13%;
}
</style>